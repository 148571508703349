<template>
  <div class="content-wrap">
    <div class="content-select no-print">
      <div class="select-header">
        <div>
          <label>所属税期：</label>
          <span>{{ fmtTaxPeriod(deductAuthorize.dqssq) }}</span>
        </div>
        <div class="items">
          <el-button type="default" @click="handleInitiate">刷新</el-button>
          <el-button type="default" @click="handlePrint">打印</el-button>
          <!--确认签名-->
          <el-button
            type="primary"
            @click="confirmQrVisible = true"
            :disabled="!deductAuthorize.sfkyqr"
          >
            确认签名
          </el-button>
          <el-button
            v-if="deductAuthorize.sfkysq !== false"
            type="primary"
            :disabled="
              !(
                deductAuthorize.isReadied &&
                deductAuthorize.deductIncomeProcessStatus != 'QEQM_Q'
              )
            "
            @click="handleApply"
            >申请统计
          </el-button>
          <el-button
            v-else
            type="primary"
            @click="applyBackVisible = true"
            :disabled="applyBackDisabled"
            >撤销统计
          </el-button>
        </div>
      </div>
      <div class="content-stage">
        <div>
          <span
            class="icon"
            :class="deductAuthorize.sfkysq === false ? 'stage' : null"
            >1</span
          >
          <span>申请统计</span>
        </div>
        <i></i>
        <div>
          <span class="icon" :class="isStatus ? 'stage' : null">2</span>
          <span>统计完成</span>
        </div>
        <i></i>
        <div>
          <span class="icon" :class="deductAuthorize.qrsj ? 'stage' : null"
            >3</span
          >
          <span>确认</span>
        </div>
      </div>
    </div>
    <div class="content-main">
      <div class="table-header">
        <div style="width: 470px">抵扣统计表</div>
        <div class="header-right">
          <div>
            <label>组织名称：</label>
            <span>{{ deductAuthorize.name }}</span>
          </div>
          <div>
            <label>组织税号：</label>
            <span>{{ deductAuthorize.taxNo }}</span>
          </div>
          <div>单位:份、元</div>
        </div>
      </div>
      <div class="table-content">
        <el-table
          :data="tableData"
          v-loading="tableLoading"
          stripe
          border
          show-summary
          :header-cell-style="handleHeaderCellStyle"
          :summary-method="getSummaries"
          style="width: 100%"
        >
          <el-table-column label="用途" width="160">
            <el-table-column label="发票类型" width="160">
              <template slot-scope="scope">
                <span>{{
                  handleValueToLabel("DeductInvoiceType", scope.row.invoiceType)
                }}</span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="抵扣">
            <el-table-column prop="deductCount" label="份数"></el-table-column>
            <el-table-column prop="deductAmount" label="金额">
              <template slot-scope="scope">
                <div>{{ "￥" + scope.row.deductAmount }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="deductValidTaxAmount" label="有效税额">
              <template slot-scope="scope">
                <div>{{ "￥" + scope.row.deductValidTaxAmount }}</div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="不抵扣">
            <el-table-column
              prop="noDeductCount"
              label="份数"
            ></el-table-column>
            <el-table-column prop="noDeductAmount" label="金额">
              <template slot-scope="scope">
                <div>{{ "￥" + scope.row.noDeductAmount }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="noDeductValidTaxAmount" label="有效税额">
              <template slot-scope="scope">
                <div>{{ "￥" + scope.row.noDeductValidTaxAmount }}</div>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <p-tip
      :visible.sync="applyStatistics"
      title="生成报表"
      :content="applyContent"
      cancelShow
      button="确定"
      width="480px"
      @confirm="handleApplyConfirm"
    >
    </p-tip>
    <p-tip
      :visible.sync="applyBackVisible"
      title="撤销统计"
      content="请确认是否撤销当前统计表？"
      cancelShow
      button="确定"
      width="480px"
      @confirm="handleApplyBackConfirm"
    >
    </p-tip>
    <p-tip
      :visible.sync="confirmQrVisible"
      title="请确认签名"
      content="确认后当前统计表将作为申报的依据"
      cancelShow
      button="确定"
      width="480px"
      @confirm="handlerInputQrPasswordVisible"
    ></p-tip>
    <p-dialog
      :visible.sync="inputQrPasswordVisible"
      width="480px"
      :disabled="!password"
      @confirm="handleQrConfirm"
    >
      <span slot="title">请输入证书密码</span>
      <el-input
        slot="content"
        type="password"
        v-model.trim="password"
      ></el-input>
    </p-dialog>
  </div>
</template>

<script>
import { tableStyle, changeMethods } from "@/util/mixins";
import PTip from "@/components/pdt/PTip";
import PDialog from "@/components/pdt/PDialog";
import {
  getStatisticFlag,
  deductApplyStatistic,
  getDeductIncomeStatistics,
  applyConfirm,
  getDeductIncomeProcessStatus,
} from "@/service/deduction";

export default {
  name: "CurrentTaxPeriodStatistics",
  components: { PTip, PDialog },
  props: ["taxPeriod"],
  mixins: [tableStyle],
  computed: {
    // 收否统计完成
    isStatus() {
      return ["SF_W", "SF_Y"].includes(this.deductAuthorize.tjzt);
    },
    applyContent() {
      return this.couldApply
        ? "请确定是否生成统计表？"
        : '当前所属税期中包含有"勾选中、取消勾选中"状态的发票，请1小时候重复';
    },
    // 撤销统计，是否可点
    applyBackDisabled() {
      let disabled =
        (this.isStatus && this.deductAuthorize.sfkyqr) ||
        (this.isStatus && this.deductAuthorize.qrsj) ||
        (this.isStatus && this.deductAuthorize.deductIncomeProcessStatus == "SQTJ_C");
      if (this.deductAuthorize.deductIncomeProcessStatus == "QEQM_Q") {
        disabled = false;
      }
      return !disabled;
    },
  },
  data() {
    return {
      applyStatistics: false, // 生成报表dialog
      couldApply: false, // 是否可以生成报表
      applyBackVisible: false, // 撤销统计dialog
      confirmQrVisible: false, // 确认签名dialog
      inputQrPasswordVisible: false, // 确认签名填写密码dialog
      password: "", // 证书密码
      deductAuthorize: {},
      tableLoading: false,
      tableData: [],
      isRPA: "",
    };
  },
  methods: {
    handlerInputQrPasswordVisible() {
      let authorizeMode = this.deductAuthorize.authorizeMode;
      if (authorizeMode === "RPA" || authorizeMode === "RPA_JCSK") {
        this.handleQrConfirm();
      } else {
        this.inputQrPasswordVisible = true;
      }
    },
    // 更新授权状态
    updateAuthorize(org) {
      if (org != undefined) {
        this.deductAuthorize = org;
      }
      this.handleInitiate();
      this.deductAuthorize = {
        ...this.deductAuthorize,
        ...this.$store.state.deductAuthorize,
      };
    },
    // 打印
    handlePrint() {
      window.print();
    },
    // 格式化所属税期
    fmtTaxPeriod(val) {
      if (val) {
        let arr = val.split("");
        arr.splice(6, 0, "月");
        arr.splice(4, 0, "年");
        return arr.join("");
      } else {
        return "--";
      }
    },
    // 初始化
    handleInitiate() {
      this.initStatic();
      this.refreshTableData();
    },
    // 初始化当前所属期统计阶段
    async initStatic() {
      if (!this.deductAuthorize.taxNo) {
        return;
      }
      const { success, data } = await getDeductIncomeProcessStatus(
        this.deductAuthorize.taxNo
      );
      if (success) {
        this.deductAuthorize = { ...this.deductAuthorize, ...data };
      }
      //   else {
      //     this.toast("未获取到当前所属期内的统计阶段", "error");
      //   }
    },
    // 统计表信息查询
    async refreshTableData() {
      if (!this.deductAuthorize.dqssq || !this.deductAuthorize.taxNo) {
        return;
      }
      this.tableLoading = true;
      const { success, data } = await getDeductIncomeStatistics({
        taxNo: this.deductAuthorize.taxNo,
        currentTaxPeriod: this.deductAuthorize.dqssq,
      });
      this.tableLoading = false;
      if (success) this.tableData = data;
    },
    // 点击申请
    async handleApply() {
      // 校验是否可申请接口
      const { success, data } = await getStatisticFlag(
        this.deductAuthorize.taxNo
      );
      if (success && data) {
        this.couldApply = true;
      }
      this.applyStatistics = true;
    },
    // 申请报表，点击确定的回调
    async handleApplyConfirm() {
      if (!this.couldApply) return;
      console.log(this.deductAuthorize);
      const { success } = await deductApplyStatistic({
        taxNo: this.deductAuthorize.taxNo,
        taxPeriod: this.deductAuthorize.dqssq,
        statisticFlag: true,
        orgId: this.deductAuthorize.orgId,
        channelType: this.deductAuthorize.channelType,
        isMock: this.deductAuthorize.isMock,
      });
      if (success)
        this.toast(
          "已提交申请统计请求，稍后刷新页面查看申请统计结果",
          "success",
          () => this.handleInitiate()
        );
    },
    // 撤销统计，点击确定的回调
    handleApplyBackConfirm() {
      if (this.deductAuthorize.deductIncomeProcessStatus == "QEQM_C") {
        this.handleConfirmBack();
      } else {
        this.handleStatisticsBack();
      }
    },
    //撤销确认弹框
    handleConfirmBack() {
      this.inputQrPasswordVisible = true;
    },
    //撤销统计
    async handleStatisticsBack() {
      const { success } = await deductApplyStatistic({
        taxNo: this.deductAuthorize.taxNo,
        taxPeriod: this.deductAuthorize.dqssq,
        statisticFlag: false,
      });
      if (success)
        this.toast(
          "已提交撤销统计请求，稍后刷新页面查看撤销统计结果",
          "success",
          () => this.handleInitiate()
        );
    },
    // 确认签名，点击确定的回调
    async handleQrConfirm() {
      let successText = "已提交确认签名请求，稍后刷新页面查看确认签名结果";
      let failText = "确认签名失败";
      let qrbz = "0";
      if (this.deductAuthorize.deductIncomeProcessStatus == "QEQM_C") {
        successText = "已提交撤销统计的请求，稍后刷新页面查看确认签名结果";
        failText = "撤销统计失败";
        qrbz = "1";
      }
      const { success, message } = await applyConfirm({
        taxNo: this.deductAuthorize.taxNo,
        qrmm: this.password,
        qrbz: qrbz,
      });
      if (success) {
        this.toast(successText, "success", () => this.handleInitiate());
      } else {
        this.toast(failText+","+message, "error");
      }
    },
    // 格式化合计行
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "总计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (
          !values.every((value) => isNaN(value)) &&
          [2, 3, 5, 6].indexOf(index) > -1
        ) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            return !isNaN(value) ? Number((prev + curr).toFixed(2)) : prev;
          }, 0);
          sums[index] = "￥" + sums[index];
        } else {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            return !isNaN(value) ? Number((prev + curr).toFixed(2)) : prev;
          }, 0);
          //   sums[index] = sums[index];
        }
      });
      return sums;
    },
  },
};
</script>

<style>
@media print {
  .no-print {
    display: none !important;
  }
}

@page {
  size: 1200px 600px;
}
</style>
<style lang="scss" scoped>
.content-select {
  box-shadow: none;

  .select-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    span {
      color: #f5222d;
    }
  }

  .content-stage {
    display: flex;
    color: #666666;
    padding: 32px 0;
    font-size: 16px;
    align-items: center;
    flex-direction: row;
    justify-content: center;

    i {
      display: inline-block;
      height: 1px;
      width: 192px;
      margin: 0 16px;
      background-color: #1890ff;
    }

    .icon {
      width: 32px;
      height: 32px;
      color: #1890ff;
      line-height: 32px;
      margin-right: 8px;
      border-radius: 50%;
      text-align: center;
      display: inline-block;
      border: 1px solid #1890ff;
    }

    .stage {
      background-color: #1890ff;
      color: #ffffff;
    }
  }
}

.content-main {
  padding: 0;
}

.table-header {
  display: flex;
  color: #666666;
  font-size: 16px;
  padding: 9px 12px;
  flex-direction: row;
  align-items: center;
  border: 1px solid #eeeeee;
  justify-content: space-between;

  .header-right {
    flex: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span {
      color: #333333;
    }
  }
}

::v-deep .el-table thead.is-group tr:first-of-type th:first-of-type {
  border-bottom: none;
  padding-left: 60px;
}

::v-deep .el-table thead.is-group tr:last-of-type th:first-of-type {
  padding-right: 60px;
}

::v-deep .el-table thead.is-group tr:first-of-type th:first-of-type:before {
  top: 0;
  left: 0;
  width: 1px;
  content: "";
  height: 97px;
  display: block;
  position: absolute;
  transform-origin: top;
  background-color: #ffffff;
  transform: rotate(-59deg);
}

::v-deep .el-table thead.is-group tr:last-of-type th:first-of-type:before {
  right: 0;
  bottom: 0;
  width: 1px;
  content: "";
  height: 97px;
  display: block;
  position: absolute;
  transform-origin: bottom;
  background-color: #ffffff;
  transform: rotate(-59deg);
}
</style>
