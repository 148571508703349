<template>
  <div class="content-wrap">
    <div class="content-select no-print">
      <income-authorized
        ref="authorized"
        style="padding: 12px 110px 12px 24px"
        @org-change="handleTaxPayerChange"
      ></income-authorized>
      <div class="select-content">
        <el-tabs v-model="activeTab">
          <el-tab-pane name="ded">
            <el-dropdown
              slot="label"
              placement="bottom"
              @command="handleSelect"
            >
              <span
                >{{ dropdownValue
                }}<i class="el-icon-arrow-down el-icon--right"></i
              ></span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="current"
                  >当前属期数据统计</el-dropdown-item
                >
                <el-dropdown-item command="history"
                  >历史属期数据统计</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </el-tab-pane>
          <el-tab-pane label="认证清单" name="query"> </el-tab-pane>
          <el-tab-pane label="异常发票" name="exception"> </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="content-main">
      <current-tax-period-statistics
        ref="currentPeriod"
        v-if="activeTab == 'ded' && dropdown == 'current'"
      ></current-tax-period-statistics>
      <history-tax-period-statistics
        v-if="activeTab == 'ded' && dropdown == 'history'"
      ></history-tax-period-statistics>
      <invoice-query v-if="activeTab == 'query'"></invoice-query>
      <exception-invoice-query
        v-if="activeTab == 'exception'"
      ></exception-invoice-query>
    </div>
    <sync-data
      class="sync-data no-print"
      current="without"
      :is-readied="isReadied"
      @sync-data="handleSyncData"
    ></sync-data>
  </div>
</template>

<script>
import CurrentTaxPeriodStatistics from "./CurrentTaxPeriodStatistics";
import HistoryTaxPeriodStatistics from "./HistoryTaxPeriodStatistics";
import InvoiceQuery from "@/views/income-deduction/component/InvoiceQuery";
import IncomeAuthorized from "@/views/income-selection/components/IncomeAuthorized";
import ExceptionInvoiceQuery from "@/views/income-deduction/component/ExceptionInvoiceQuery";
import SyncData from "@/views/income-selection/components/SyncData";

export default {
  name: "ChkFprz",
  components: {
    SyncData,
    IncomeAuthorized,
    InvoiceQuery,
    ExceptionInvoiceQuery,
    CurrentTaxPeriodStatistics,
    HistoryTaxPeriodStatistics,
  },
  data() {
    return {
      activeTab: "ded",
      isReadied: false,
      dropdown: "current",
      dropdownValue: "当前属期数据统计",
    };
  },
  methods: {
    handleSelect(val) {
      this.dropdownValue =
        val == "current" ? "当前属期数据统计" : "历史属期数据统计";
      this.dropdown = val;
    },
    handleTaxPayerChange(val) {
      console.log("handleTaxPayerChange...", JSON.stringify(val));
      // this.$refs.currentPeriod.handleInitiate(val);
      this.isReadied = val.isReadied;
      this.$refs.currentPeriod.updateAuthorize(val);
      // this.$refs.authorized.handleAuthorized(false, val);
    },
    // //子组件通知父组件
    // handleAuthorized(val) {
    //   console.log("handleAuthorized...")
    //   this.isReadied = val.isReadied;
    //   this.$refs.currentPeriod.updateAuthorize();
    //   // this.$refs.authorized.handleAuthorized()
    // },
    handleSyncData() {
      // console.log("handleSyncData...");
      this.$refs.currentPeriod.refreshTableData();
    },
  },
};
</script>

<style lang="scss" scoped>
.content-wrap {
  position: relative;
}

.select-content {
  padding: 18px 0 0;
}

.sync-data {
  top: 15px;
  right: 12px;
  position: absolute;
}
</style>
